<template>
    <div ref="container" :class="['conditions-container text-caption', {'overflowing': isOverflowing}]">
        <span v-if="action && action.always_trigger"><b>Always</b></span>
        <template v-else>
            <single-condition-description :key="condition.id" v-for="(condition, index) in action.conditions" :condition="condition" :conditions="action.conditions" :index="index"></single-condition-description>
        </template>
    </div>
</template>

<script>
import SingleConditionDescription from './SingleConditionDescription.vue';
export default {
    components: { SingleConditionDescription },
    props: ['action'],
    data(){
        return {
            isOverflowing: false
        }
    },
    methods: {
        updateOverflow(){
            var element = this.$refs.container;
            if(element){
                // waiting for full render of container
                setTimeout(()=> {
                    this.isOverflowing = (element.offsetHeight < element.scrollHeight || element.offsetWidth < element.scrollWidth)
                }, 100)
            }
        }
    },
    mounted(){
        this.updateOverflow()
    },
    updated(){
        this.updateOverflow()
    }
}
</script>

<style lang="scss" scoped>
.conditions-container{
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    height: 24px;
    flex-wrap: wrap;
    white-space: nowrap;
    position: relative;
    -webkit-box-flex: 0;
    flex-grow: 1;
    flex-shrink: 1;
    padding-right: 20px;
    overflow: hidden;
    justify-content: flex-start;
}
.overflowing::after {
    content: "...";
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
}
</style>