<template>
    <v-card dark min-height="700" color="secondary" class="d-flex flex-column script-dialog">
        <div elevation="0" class="d-flex align-center pa-1">
            <v-btn color="success" @click="executeWorkerScript" text><v-icon color="success" left>mdi-play</v-icon>Run Script</v-btn>
        </div>
        <v-divider></v-divider>
        <div class="d-flex flex-grow-1">
            <div class="d-flex flex-column">
                <div class="text-caption grey--text px-3 py-2"><b>Dynamic Variables JSON</b></div>
                <v-divider ></v-divider>
                <MonacoEditor
                    width="300"
                    height="100%"
                    theme="vs-dark"
                    language="json"
                    :value="jsonVariables"
                    :options="{
                        automaticLayout: true,
                        fontSize: 12,
                        scrollbar: {
                            vertical: false,
                            verticalScrollbarSize: 4,
                        },
                        minimap: {
                            enabled: false
                        },
                        contextmenu: false
                    }"
                    @change="onJSONChange"
                ></MonacoEditor>
            </div>
            <v-divider vertical></v-divider>
            <div class="d-flex flex-column">
                <div class="d-flex flex-column flex-grow-1">
                    <div class="text-caption grey--text px-3 py-2"><b>JS Script</b></div>
                    <v-divider></v-divider>
                    <MonacoEditor
                        width="800"
                        height="100%"
                        theme="vs-dark"
                        language="javascript"
                        @change="onChange"
                        :options="{
                            automaticLayout: true,
                            fontSize: 14,
                            minimap: {
                                enabled: true
                            },
                            contextmenu: false
                        }"
                        :value="userScript"
                    ></MonacoEditor>
                </div>
                <v-divider></v-divider>
                <v-card  min-height="150" outlined color="secondary" dark>
                    <div class="d-flex align-center">
                        <div class="text-caption grey--text px-3 py-2"><b>Output</b></div>
                        <v-spacer></v-spacer>
                        <v-btn small dark @click="consoleOutput=''" text color="grey">
                            <v-icon left>mdi-cancel</v-icon>
                        </v-btn>
                    </div>
                    <v-divider></v-divider>
                    <MonacoEditor
                        width="800"
                        height="150"
                        theme="vs-dark"
                        language="plaintext"
                        :options="{
                            automaticLayout: true,
                            fontSize: 12,
                            readOnly: true, // Prevent editing
                            lineNumbers: 'off', // Hide line numbers
                            minimap: {
                                enabled: false // Disable minimap
                            },
                            scrollbar: {
                                vertical: 'visible', // Always show vertical scrollbar
                                horizontal: 'visible', // Always show horizontal scrollbar
                                useShadows: false // Disable scrollbar shadows for a flat look
                            },
                            renderLineHighlight: 'none', // Disable current line highlighting
                            cursorBlinking: 'solid', // Make the cursor solid instead of blinking
                            cursorStyle: 'block',
                            contextmenu: false // Disable context menu
                        }"
                        :value="consoleOutput"
                    ></MonacoEditor>
                </v-card>
            </div>
        </div>
    </v-card>
</template>

<script>
import BuilderApi from '@/api/BuilderApi';
import MonacoEditor from 'monaco-editor-vue';
import Worker from 'worker-loader!@/workers/customScriptExecutorWorker.js';
import Types from '@/configs/questionTypes';
import { mapMutations } from 'vuex';



const DEFAULT_RADIO_GROUP_NAME = 'default_radio';
const DEFAULT_CHECKBOX_GROUP_NAME = 'default_checkbox';

export default {
    name: 'ScriptEditor',
    components: { MonacoEditor },
    props: {
        script: {
            type: String,
            default: ''
        },
        viewable: {
            type: Boolean
        },
        rules: {
            type: Array,
            default: () => []
        }
    },
    data(){
        return {
          userScript: this.script || '',
          error: '',
          jsonVariables: null,
          variables: null,
          executionResult: null,
          worker: null,
          workerResult: null,
          consoleOutput: '',
        }
    },
    watch: {
        viewable: {
            immediate: true,
            handler(val){
                if(val){
                    this.userScript = this.script;
                }else{
                    this.$emit('save', this.userScript);
                }
            }
        }
    },
    methods: {
        ...mapMutations(['showSnackBar']),
        onChange(newValue){
            this.userScript = newValue;
        },

        onJSONChange(newValue){
            this.jsonVariables = newValue;
        },

        executeWorkerScript(){
            if (window.Worker) {
                this.worker = new Worker();

                const dynamicVariables = this.variables;

                if(this.jsonVariables){
                    try {
                        const parsedVariables = JSON.parse(this.jsonVariables);
                        this.variables = parsedVariables;
                    } catch (error) {
                        return this.showSnackBar({
                            text: "Invalid JSON format. Please check your JSON and try again.", 
                            color: 'error', 
                            timeout: 2000
                        })
                    }
                }
                
                this.worker.postMessage({
                    script: this.userScript,
                    dynamicVariables
                });
                // Listen for messages from the worker
                this.worker.onmessage = (event) => {
                    let message = '';
                    const timestamp = new Date().toISOString();

                    if (typeof event.data === 'object') {
                        if (event.data.type === 'result') {
                            let validationResult = true;
                            let validationMessage = '';
                            // Apply each rule to the output
                            for (const rule of this.rules) {
                                validationResult = rule(event.data.data);
                                if (!validationResult || typeof validationResult === 'string') {
                                    validationMessage = typeof validationResult === 'string' ? validationResult : "The output does not meet the specified conditions.";
                                    this.consoleOutput += `[${timestamp}] VALIDATION ERROR: ${validationMessage}\n`;
                                    return; // Stop further processing if validation fails
                                }
                            }


                            // Handle results from the worker script execution
                            if (event.data.data && typeof event.data.data === 'object') {
                                message = JSON.stringify(event.data.data);
                            } else {
                                message = event.data.data;
                            }
                            this.consoleOutput += `[${timestamp}] RESULT: ${message}\n`;
                        } else if (event.data.type === 'error') {
                            // Handle errors from the worker script execution
                            message = event.data.message;
                            this.consoleOutput += `[${timestamp}] ERROR: ${message}\n`;
                        } else if (event.data.type === 'console') {
                            // Handle console method calls from the worker script
                            const consoleMethod = event.data.method || 'log';
                            message = event.data.arguments.join(' ');
                            this.consoleOutput += `[${timestamp}] ${consoleMethod.toUpperCase()}: ${message}\n`;
                        }
                    } else {
                        // If the message is not an object, log it directly
                        this.consoleOutput += `[${timestamp}] ${event.data}\n`;
                    }
                };

                this.worker.onerror = function(e) {
                    const timestamp = new Date().toISOString();
    
                    // Append error information to consoleOutput
                    this.consoleOutput += `[${timestamp}] ERROR: ${e.message} (Line: ${e.lineno} in ${e.filename})\n`;
                    console.error('Error received from worker', e);
                };

                // Post data to the worker
            } else {
                console.log('Your browser does not support Web Workers.');
            }
        },
        async loadVariables(){
            const res = await BuilderApi.listDynamicVariables(this.$route.params.surveyId);
            if(res.data){
                this.variables = res.data;
            }
            return res;
        },

        async loadFields(){
            const res = await BuilderApi.listFields(this.$route.params.surveyId);
            if(res.data){
                this.fields = res.data;
            }
            return res;
        },
        generateFields(questions){
            const fields = {}

            questions.forEach(question => {
                // declare empty variable
                fields[question.variable_name] = null;

                if(question.type === Types.MATRIX){
                    const columnGroups = {}

                    for (let columnIndex = 0; columnIndex < question.properties.columns.length; columnIndex++) {
                        const column = question.properties.columns[columnIndex];
                        let group_name = column.group_name || null;

                        if(column.type === 'radio' && !group_name){
                            group_name = DEFAULT_RADIO_GROUP_NAME;
                        } else if(column.type === 'checkbox' && !group_name){
                            group_name = DEFAULT_CHECKBOX_GROUP_NAME;
                        }else if(!group_name){
                            group_name = column.id;
                        }

                        if(!columnGroups[group_name]){
                            columnGroups[group_name] = []
                        }

                        columnGroups[group_name].push(columnIndex);
                    }

                    question.properties?.rows?.forEach(row => {

                        if(fields[question.variable_name] === null){
                            fields[question.variable_name] = {}
                        }
                        // declare empty variable for each row
                        fields[question.variable_name][row.id] = {}
                        
                        question.properties?.columns?.forEach((column) => {

                            if(column.type === 'radio'){
                                
                                let group_name = column.group_name || DEFAULT_RADIO_GROUP_NAME;
                                if(fields[question.variable_name][row.id][group_name] === undefined){
                                    // randomly select a column as an example
                                    if(columnGroups[group_name] && columnGroups[group_name].length > 0){
                                        const randomColumnIndex = columnGroups[group_name][Math.floor(Math.random() * columnGroups[group_name].length)];
                                        const randomColumn = question.properties.columns[randomColumnIndex];
                                        fields[question.variable_name][row.id][group_name] = {
                                            col_id: randomColumn.id,
                                            col_title: randomColumn.title || null,
                                            row_title: row.title || null,
                                            type: randomColumn.type,
                                            value: randomColumn.title,
                                        };
                                    }else{
                                        fields[question.variable_name][row.id][group_name] = {
                                            col_id: column.id,
                                            col_title: column.title || null,
                                            row_title: row.title || null,
                                            type: column.type,
                                            value: column.title,
                                        };
                                    }
                                }
                            }

                            if(column.type === 'checkbox'){
                                let group_name = column.group_name || DEFAULT_CHECKBOX_GROUP_NAME;
                                if(fields[question.variable_name][row.id][group_name] === undefined){
                                    // randomly select a column as an example
                                    if(columnGroups[group_name] && columnGroups[group_name].length > 0){
                                        const randomColumnIndex = columnGroups[group_name][Math.floor(Math.random() * columnGroups[group_name].length)];
                                        const randomColumn = question.properties.columns[randomColumnIndex];
                                        fields[question.variable_name][row.id][group_name] = [{
                                            col_id: randomColumn.id,
                                            col_title: randomColumn.title || null,
                                            row_title: row.title || null,
                                            type: randomColumn.type,
                                            value: randomColumn.title,
                                        }];
                                    }else{
                                        fields[question.variable_name][row.id][group_name] = [{
                                            col_id: column.id,
                                            col_title: column.title || null,
                                            row_title: row.title || null,
                                            type: column.type,
                                            value: column.title,
                                        }];
                                    }
                                }
                            }

                            if(column.type === 'dropdown'){
                                // select random option
                                const randomOption = column.properties.options[Math.floor(Math.random() * column.properties.options.length)];
                                fields[question.variable_name][row.id][column.id] = {
                                    col_id: column.id,
                                    col_title: column.title || null,
                                    row_title: row.title || null,
                                    type: column.type,
                                    value: randomOption || null,
                                };
                            }

                            if(column.type === 'text'){
                                fields[question.variable_name][row.id][column.id] = {
                                    col_id: column.id,
                                    col_title: column.title || null,
                                    row_title: row.title || null,
                                    type: column.type,
                                    value: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
                                };
                            }

                            if(column.type === 'numeric'){
                                let min = column.properties && column.properties.min_number ? column.properties.min_number : 0;
                                let max = column.properties && column.properties.max_number ? column.properties.max_number : 100;

                                let numberValue;

                                if (column.properties?.allow_decimal_numbers) {
                                    // Generate a decimal number within the range
                                    numberValue = Math.random() * (max - min) + min;
                                } else {
                                    // Generate an integer within the range
                                    numberValue = Math.floor(Math.random() * (max - min + 1)) + min;
                                }

                                fields[question.variable_name][row.id][column.id] = {
                                    col_id: column.id,
                                    col_title: column.title || null,
                                    row_title: row.title || null,
                                    type: column.type,
                                    value: numberValue,
                                };
                            }

                        });
                    });
                }

                if(question.type === Types.DATE_PICKER){
                    // the format 2024-03-14 is used for date picker
                    fields[question.variable_name] = this.$date().format('YYYY-MM-DD');
                }

                if(question.type === Types.EMAIL){
                    // generate random email
                    fields[question.variable_name] = `user${Math.floor(Math.random() * 1000)}@example.com`;
                }

                if(question.type === Types.PHONE_NUMBER){
                    // generate random 10 digits phone number
                    fields[question.variable_name] = `+1${Math.floor(Math.random() * 1000000000)}`;
                }

                if(question.type === Types.YESNO){
                    // string yes or no. randomly select one
                    fields[question.variable_name] = Math.random() > 0.5 ? 'yes' : 'no';
                }

                if(question.type === Types.RATING){
                    // calculate random rating based on multiple_choice_items length
                    fields[question.variable_name] = Math.floor(Math.random() * question.multiple_choice_items.length);
                }

                if (question.type === Types.CHECKBOX) {
                    const shuffledOptions = question.multiple_choice_items
                        .map(value => ({ value, sort: Math.random() }))
                        .sort((a, b) => a.sort - b.sort)
                        .map(({ value }) => value);
                    const randomLength = Math.floor(Math.random() * shuffledOptions.length) + 1; // Ensure at least one selection
                    const selectedOptions = shuffledOptions.slice(0, randomLength).map(option => option.value);
                    fields[question.variable_name] = selectedOptions;
                }

                if ([Types.DROPDOWN, Types.MULTIPLE_CHOICE].includes(question.type) && question.multiple_choice_items.length > 0) {
                    const randomIndex = Math.floor(Math.random() * question.multiple_choice_items.length);
                    fields[question.variable_name] = question.multiple_choice_items[randomIndex].value;
                }

                if(question.type === Types.CUSTOM_FIELD){
                    fields[question.variable_name] = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.';
                }

                if(question.type === Types.NET_PROMOTER_SCORE){
                    // generate random number between 0 and 10
                    fields[question.variable_name] = Math.floor(Math.random() * 10);
                }

                if(question.type === Types.NUMERIC_INPUT){
                    let min = question.properties && question.properties.min_number ? question.properties.min_number : 0;
                    let max = question.properties && question.properties.max_number ? question.properties.max_number : 100;

                    let numberValue;

                    if (question.properties?.allow_decimal_numbers) {
                        // Generate a decimal number within the range
                        numberValue = Math.random() * (max - min) + min;
                    } else {
                        // Generate an integer within the range
                        numberValue = Math.floor(Math.random() * (max - min + 1)) + min;
                    }
                    fields[question.variable_name] = numberValue
                }

            });

            return fields;
        }
        
    },
    async created(){
        try {
            this.loading = true;

            const variablesPromise = this.loadVariables();
            const fieldsPromise = this.loadFields();

            const [variablesRes, fieldsRes] = await Promise.all([variablesPromise, fieldsPromise]);

            if(variablesRes.data){
                const dynamicVariables = {}
                variablesRes.data.forEach(variable => {
                    // Initialize the variable value to null or its original value
                    let parsedValue = variable.value || null;

                    // Attempt to parse the value if it exists
                    if (variable.value) {
                        try {
                            // Try parsing the value as JSON
                            parsedValue = JSON.parse(variable.value);
                        // eslint-disable-next-line
                        } catch (e) {}
                    }

                    // Assign the parsed or original value to the dynamicVariables object
                    dynamicVariables[variable.name] = parsedValue;
                });
                this.variables = dynamicVariables
            }

            if(fieldsRes.data){
                this.variables['fields'] = this.generateFields(fieldsRes.data);
            }

            this.variables['utility'] = {
                viewedAt: new Date().getTime() - 1000 * 60,
                startedAt: new Date().getTime(),
                totalOnScreenTime: Math.floor(Math.random() * (5 * 60 * 1000 - 60 * 1000 + 1)) + 60 * 1000,
                respondentId: window.pubUUID || null,
                resumedAt: null,
            }

            this.variables['metadata'] = {}

            this.jsonVariables = JSON.stringify(this.variables, null, 2);
        } catch (error) {
            console.log(error);
        } finally {
            this.loading = false;
        }
    },
    beforeDestroy() {
        // Terminate the worker when the component is about to be destroyed
        if (this.worker) {
            this.worker.terminate();
        }
    }
}
</script>

<style lang="scss" scoped>
.script-dialog{
    overflow: hidden;
}
</style>