<template>
    <div style="max-width:250px" class="d-flex flex-grow-1 align-center">
        <v-icon class="mr-2" size="20" color="deep-purple accent-3">mdi-open-in-new</v-icon>
        <div class="text-caption ellipsis-text">{{text}}</div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    props: ['questionId', 'actionId'],
    computed: {
        ...mapGetters({
            actions: 'logic/actions'
        }),
        action(){
           return this.actions[this.questionId] && this.actions[this.questionId].find(action => action.id === this.actionId)
        },
        text(){
            return `${this.action && this.action.submit_answers? 'Submit and':''} Redirect to ${this.link}`
        },
        link(){
          return this.action && this.action.to && this.action.to.replace(/^https?:\/\//, '')
        }
    }
}
</script>

<style lang="scss" scoped>
.ellipsis-text{
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

</style>