<template>
    <div v-if="questionActions">
        <action-list-item 
            v-for="(action, index) in questionActions" 
            :key="action.id" 
            :action="action" 
            :question="question"
            :isLast="index === questionActions.length - 1"
        >
        </action-list-item>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ActionListItem from './ActionListItem.vue'
export default {
  components: { ActionListItem },
    props: ['question'],
    computed:{
        ...mapGetters({
            actions: 'logic/actions'
        }),
        questionActions(){
            return this.actions[this.question.id]
        }
    }
}
</script>

<style lang="scss" scoped>

</style>