var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"d-flex flex-column script-dialog",attrs:{"dark":"","min-height":"700","color":"secondary"}},[_c('div',{staticClass:"d-flex align-center pa-1",attrs:{"elevation":"0"}},[_c('v-btn',{attrs:{"color":"success","text":""},on:{"click":_vm.executeWorkerScript}},[_c('v-icon',{attrs:{"color":"success","left":""}},[_vm._v("mdi-play")]),_vm._v("Run Script")],1)],1),_c('v-divider'),_c('div',{staticClass:"d-flex flex-grow-1"},[_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"text-caption grey--text px-3 py-2"},[_c('b',[_vm._v("Dynamic Variables JSON")])]),_c('v-divider'),_c('MonacoEditor',{attrs:{"width":"300","height":"100%","theme":"vs-dark","language":"json","value":_vm.jsonVariables,"options":{
                    automaticLayout: true,
                    fontSize: 12,
                    scrollbar: {
                        vertical: false,
                        verticalScrollbarSize: 4,
                    },
                    minimap: {
                        enabled: false
                    },
                    contextmenu: false
                }},on:{"change":_vm.onJSONChange}})],1),_c('v-divider',{attrs:{"vertical":""}}),_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"d-flex flex-column flex-grow-1"},[_c('div',{staticClass:"text-caption grey--text px-3 py-2"},[_c('b',[_vm._v("JS Script")])]),_c('v-divider'),_c('MonacoEditor',{attrs:{"width":"800","height":"100%","theme":"vs-dark","language":"javascript","options":{
                        automaticLayout: true,
                        fontSize: 14,
                        minimap: {
                            enabled: true
                        },
                        contextmenu: false
                    },"value":_vm.userScript},on:{"change":_vm.onChange}})],1),_c('v-divider'),_c('v-card',{attrs:{"min-height":"150","outlined":"","color":"secondary","dark":""}},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"text-caption grey--text px-3 py-2"},[_c('b',[_vm._v("Output")])]),_c('v-spacer'),_c('v-btn',{attrs:{"small":"","dark":"","text":"","color":"grey"},on:{"click":function($event){_vm.consoleOutput=''}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-cancel")])],1)],1),_c('v-divider'),_c('MonacoEditor',{attrs:{"width":"800","height":"150","theme":"vs-dark","language":"plaintext","options":{
                        automaticLayout: true,
                        fontSize: 12,
                        readOnly: true, // Prevent editing
                        lineNumbers: 'off', // Hide line numbers
                        minimap: {
                            enabled: false // Disable minimap
                        },
                        scrollbar: {
                            vertical: 'visible', // Always show vertical scrollbar
                            horizontal: 'visible', // Always show horizontal scrollbar
                            useShadows: false // Disable scrollbar shadows for a flat look
                        },
                        renderLineHighlight: 'none', // Disable current line highlighting
                        cursorBlinking: 'solid', // Make the cursor solid instead of blinking
                        cursorStyle: 'block',
                        contextmenu: false // Disable context menu
                    },"value":_vm.consoleOutput}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }