var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('edit-action',{attrs:{"question":_vm.question,"actionId":_vm.action.id}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"d-flex align-center pa-3",attrs:{"color":hover? 'deep-purple lighten-5': '',"outlined":""}},[_c('conditions-description',{attrs:{"action":_vm.action}}),(_vm.hasError)?_c('v-tooltip',{attrs:{"bottom":"","max-width":"250px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"action-badge action-badge-error pa-1"},'div',attrs,false),on),[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"error"}},[_vm._v("mdi-heart-broken")]),_c('span',{staticClass:"text-caption"},[_vm._v("Broken action")])],1)]}}],null,true)},[_c('span',[_vm._v("This action is not active. Please edit or remove this action.")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"action-badge"},'div',attrs,false),on),[_c('v-icon',{attrs:{"color":"deep-purple accent-3"}},[_vm._v(" mdi-lightning-bolt ")]),_c('span',{staticClass:"text-caption ellipsis-text"},[_vm._v(_vm._s(_vm.actionText)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.actionText))])]),(hover)?_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleActionEdit.apply(null, arguments)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"loading":_vm.deleting,"disabled":_vm.deleting,"small":"","icon":""},on:{"click":_vm.handleDelete}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)],1):_vm._e()],1)]}}])}),(_vm.isLast)?_c('div',{staticClass:"py-2"}):_c('div',{staticClass:"text-caption d-flex grey--text py-2"},[_c('v-icon',{attrs:{"color":"grey","small":"","left":""}},[_vm._v("mdi-arrow-down-thin")]),_c('span',[_vm._v("if previous action was skipped")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }