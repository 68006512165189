<template>
    <div class="fill-height">
        <div id="vf-logic-editor" style="height: 100%;"></div>
        <v-navigation-drawer touchless permanent class="d-flex nav-drawer" clipped width="400" app>

            <div class="d-flex py-4 align-center">
                <v-btn class="ml-2" @click="$router.go(-1)" color="primary" text>
                    <v-icon left>mdi-arrow-left</v-icon>
                    Back to form builder
                </v-btn>
            </div>
            <v-divider></v-divider>
            <v-fade-transition>
                <question-node-properties v-if="activeQuestionNode" :questionNode="activeQuestionNode"></question-node-properties>
            </v-fade-transition>
            <div class="py-4" v-if="!questions || !questions.length">
                <no-questions></no-questions>
            </div>
            <div v-if="!activeQuestionNode" class="pa-4">
                <select-node-placeholder></select-node-placeholder>
            </div>
            <!-- TODO: add message that user need to select a node-->
        </v-navigation-drawer>
    </div>
</template>

<script>
import Vue from "vue";
import Drawflow from "../../../../../utils/drawflow";
import { mapActions, mapGetters } from 'vuex';
import QuestionNode from './QuestionNode.vue'
import RedirectItem from './RedirectItem.vue'
import QuestionNodeProperties from './QuestionNodeProperties.vue';
import {getRandomInt} from '../../../../../utils/numberOperations'
import SubmitNode from './SubmitNode.vue';
import DropOffNode from './DropOffNode.vue'
import NoQuestions from '../../results/NoQuestions.vue';
import SelectNodePlaceholder from './SelectNodePlaceholder.vue';
export default {
    components: { QuestionNodeProperties, NoQuestions, SelectNodePlaceholder },
    data(){
        return {
            editor: null, 
            questionNodes: {},
            redirectNodes: {},
            submitNodes: {},
            dropOffNodes: {},
            activeQuestionNode: null,
            openNodeDrawer: false,
            loading: false
        }
    },
    watch: {
        edits(val){
            this.refreshEditConnections(val)
        },
        actions(){
            this.refreshActionConnections()
        }
    },
    inject: ['survey'],
    computed: {
        ...mapGetters({
            actions: 'logic/actions',
            edits: 'logic/edits',
            questions: 'logic/questions',
        })
    },
    methods: {
        ...mapActions({
            initActionsFromSurvey: 'logic/initActionsFromSurvey',
            addNewAction: 'logic/addNewAction',
        }),

        handleNodeSelect(nodeId){
            const node = this.editor.getNodeFromId(nodeId)
            if(node.name.includes('question-')){
                this.activeQuestionNode = node
            }
        },
        handleConnectionCreated({ output_id, input_id, output_class, input_class }){
            const outputNode = this.editor.getNodeFromId(output_id)
            const inputNode = this.editor.getNodeFromId(input_id)
            this.editor.removeSingleConnection(output_id, input_id, output_class, input_class)
            if(outputNode && outputNode.data && outputNode.data.question){
                this.addNewAction({question: outputNode.data.question, override: {
                    to: inputNode.data.question.id
                }})
                this.activeQuestionNode = outputNode;
            }

        },
        syncRemovedActions(){
            let actions = []
            Object.values(this.actions).forEach(actionGroup => actions=[...actions, ...actionGroup])

            const redirectNodesEntries = Object.entries(this.redirectNodes)
            redirectNodesEntries.forEach(redirectEntry => {
                const actionId = redirectEntry[0];
                const redirectNodeId = redirectEntry[1];
                const existingAction = actions.find(action=>action.type === 'redirect' && action.id===actionId)
                if(!existingAction){
                    this.editor.removeNodeId(`node-${redirectNodeId}`)
                    delete this.redirectNodes[actionId] 
                }
            })

            const submitNodesEntries = Object.entries(this.submitNodes)
            submitNodesEntries.forEach(submitEntry => {
                const actionId = submitEntry[0];
                const submitNodeId = submitEntry[1];
                const existingAction = actions.find(action=>action.type === 'submit' && action.id===actionId)
                if(!existingAction){
                    this.editor.removeNodeId(`node-${submitNodeId}`)
                    delete this.submitNodes[actionId] 
                }
            })

            const dropOffNodesEntries = Object.entries(this.dropOffNodes)
            dropOffNodesEntries.forEach(dropOffEntry => {
                const actionId = dropOffEntry[0];
                const dropOffNodeId = dropOffEntry[1];
                const existingAction = actions.find(action=>action.type === 'drop-off' && action.id===actionId)
                if(!existingAction){
                    this.editor.removeNodeId(`node-${dropOffNodeId}`)
                    delete this.dropOffNodes[actionId] 
                }
            })
        },
        refreshActionConnections(){
            const className = 'action-path'
            const errorClassName = 'error-path'
            if(this.editor){
                this.editor.removeConnectionsByPathClass(className)
                this.editor.removeConnectionsByPathClass(errorClassName)
                this.syncRemovedActions()
                const actionsEntries = Object.entries(this.actions) || null
                // add actions connections
                actionsEntries && actionsEntries.forEach(entry => {
                    const questionId = entry[0]
                    const actions = entry[1]
                    actions && actions.forEach(action => {
                        if(action && this.questionNodes[questionId]){
                            const customClass = action.broken?errorClassName: className
                            if(action.type === 'go-to' && this.questionNodes[action.to]){
                                return this.editor.addConnection(this.questionNodes[questionId], this.questionNodes[action.to], "output_1", "input_1", customClass, true);
                            }
                           
                            if(['redirect', 'submit', 'drop-off'].includes(action.type)){
                                let nodeId = null;
                                let component = null;
                                let properties = {};
                                if(action.type === 'redirect'){
                                    nodeId = this.redirectNodes[action.id]
                                    component = RedirectItem
                                    properties = {questionId, actionId: action.id}
                                }
                                if(action.type === 'submit'){
                                    nodeId = this.submitNodes[action.id]
                                    component = SubmitNode
                                }
                                if(action.type === 'drop-off'){
                                    nodeId = this.dropOffNodes[action.id]
                                    component = DropOffNode
                                }
    
                                if(!nodeId){
                                    const nodeName = `${action.type}-${action.id}-${questionId}`;
                                    this.editor.registerNode(nodeName, component, properties);
                                    const questionNode = this.editor.getNodeFromId(this.questionNodes[questionId])
                                    const pos_x = questionNode.pos_x+200+getRandomInt(0, 100)
                                    const pos_y = questionNode.pos_y+250+getRandomInt(0, 100)
                                    nodeId = this.editor.addNode(nodeName, 1, 0, pos_x, pos_y, `${action.type}-node`, {name:nodeName ,questionId, actionId: action.id}, nodeName, "vue");
                                    if(action.type === 'redirect'){
                                        this.redirectNodes[action.id] = nodeId;
                                    }
                                    if(action.type === 'submit'){
                                        this.submitNodes[action.id] = nodeId;
                                    }
                                    if(action.type === 'drop-off'){
                                        this.dropOffNodes[action.id] = nodeId;
                                    }
                                }
                                
                                return this.editor.addConnection(this.questionNodes[questionId], nodeId, "output_1", "input_1", customClass, true);
                            }
                        }
                    })
                })
            }
        },
        refreshEditConnections(editsValue){
            const className = 'edit'
            this.editor.removeConnectionsByPathClass(className)
            const editEntries = Object.entries(editsValue || this.edits) || null
            editEntries.forEach(entry => {
                const questionId = entry[0]
                const action = entry[1]
                if(action && action.type === 'go-to' && this.questionNodes[questionId] && this.questionNodes[action.to]){
                    this.editor.addConnection(this.questionNodes[questionId], this.questionNodes[action.to], "output_1", "input_1", className, true);
                }
            })
        },
        initEditor(){
            const id = document.getElementById("vf-logic-editor");
            this.editor = new Drawflow(id, Vue, this);
            this.editor.useuuid = true;
            this.editor.zoom_value = 0.05;
            this.editor.on('nodeSelected', this.handleNodeSelect)
            this.editor.on('connectionCreated', this.handleConnectionCreated)
            this.editor.start();

            this.questions.forEach((question, index) => {
                const nodeName = `question-${question.id}`
                this.editor.registerNode(nodeName, QuestionNode, {question, index});
                const nodeId = this.editor.addNode(nodeName, index!==0 && 1, 1,250*(index)+16, 100*(index)+16, "", {name:nodeName ,question}, nodeName, "vue");
                this.questionNodes[question.id] = nodeId

                if(index+1 === this.questions.length){
                    const submitNodeName = `submit-${question.id}`;
                    this.editor.registerNode(submitNodeName, SubmitNode);
                    const submitNodeId = this.editor.addNode(submitNodeName, 1, 0, 250*(index+1)+16, 100*(index+1)+16, `submit-node`, {name:submitNodeName}, submitNodeName, "vue");
                    this.editor.addConnection(nodeId, submitNodeId, "output_1", "input_1", 'default-path', true);
                }
                
                if(index!==0){
                    this.editor.addConnection(this.questionNodes[this.questions[index-1].id], this.questionNodes[question.id], "output_1", "input_1", 'default-path', true);
                }
            })

            this.refreshActionConnections()
        },
        preventNav(event) {
            event.preventDefault();
            // Chrome requires returnValue to be set.
            event.returnValue = "";
        }
    },
    beforeMount() {
        window.addEventListener("beforeunload", this.preventNav)
        this.$once("hook:beforeDestroy", () => {
            window.removeEventListener("beforeunload", this.preventNav);
        })
    },
    async mounted() {
        try {
            this.loading = true
            // needed to look for question refs
            await this.initActionsFromSurvey(this.$route.params.surveyId)
            this.initEditor()
            this.loading = false
        } catch (error) {
            console.error('initActionsFromSurvey', error);
        }
    },
}
</script>

<style lang="scss" scoped>
    .nav-drawer::v-deep .v-navigation-drawer__content{
        overflow: hidden;
        display: flex;
        flex-direction: column;
        flex: 1;
    }
</style>