<template>
    <div style="max-width:200px" class="d-flex flex-grow-1 align-center">
        <v-tooltip v-if="hasSkipAction" bottom>
            <template v-slot:activator="{ on, attrs }">
                <div class="mr-2" v-bind="attrs" v-on="on">
                    <v-icon color="deep-purple accent-3" small>mdi-debug-step-over</v-icon>
                </div>
            </template>
            <span class="text-caption">Contains Skip Action</span>
        </v-tooltip>
        <v-tooltip v-if="hasLoopAction" bottom>
            <template v-slot:activator="{ on, attrs }">
                <div class="mr-2" v-bind="attrs" v-on="on">
                    <v-icon color="deep-purple accent-3" small>mdi-sync</v-icon>
                </div>
            </template>
            <span class="text-caption">Contains Loop Action</span>
        </v-tooltip>
        <question-item :question="question"></question-item>
        <div class="question-node-status">
            <v-fade-transition hide-on-leave>
                <v-chip v-if="hasError && !editing" color="error" small>
                    <v-icon small left>
                        mdi-alert
                    </v-icon>
                    Broken actions
                </v-chip>
            </v-fade-transition>
            <v-fade-transition hide-on-leave>
                <v-chip v-if="editing" small>
                    <v-icon small left>
                        mdi-pencil
                    </v-icon>
                    Editing
                </v-chip>
            </v-fade-transition>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import QuestionItem from './QuestionItem.vue';

export default {
    components: { QuestionItem },
    props: ['question'],
    computed: {
        ...mapGetters({
            actions: 'logic/actions',
            edits: 'logic/edits'
        }),
        hasError(){
            if(this.actions && this.actions[this.question.id] && this.actions[this.question.id]){
                for (let index = 0; index < this.actions[this.question.id].length; index++) {
                    const action = this.actions[this.question.id][index];
                    if(action && action.broken){
                        return true
                    }
                }
            }
            return false
        },
        hasSkipAction(){
            if(this.actions && this.actions[this.question.id] && this.actions[this.question.id]){
                for (let index = 0; index < this.actions[this.question.id].length; index++) {
                    const action = this.actions[this.question.id][index];
                    if(action && action.type && action.type==='skip'){
                        return true
                    }
                }
            }
            return false
        },
        hasLoopAction(){
            if(this.actions && this.actions[this.question.id] && this.actions[this.question.id]){
                for (let index = 0; index < this.actions[this.question.id].length; index++) {
                    const action = this.actions[this.question.id][index];
                    if(action && action.type && action.type==='loop'){
                        return true
                    }
                }
            }
            return false
        },
        editing(){
            if(this.edits && this.edits[this.question.id]){
                return true
            }
            return false
        }
    }
}
</script>

<style lang="scss" scoped>
.question-node-status{
    position: absolute;
    bottom: -32px
}
</style>