<template>
    <edit-action-wrapper :question="question">
        <v-btn elevation="0" text color="primary" @click="handleAddAction"><v-icon left>mdi-plus</v-icon>Add action</v-btn>
    </edit-action-wrapper>
</template>

<script>
import { mapActions } from 'vuex'
import EditActionWrapper from './EditActionWrapper.vue'
export default {
    data(){
        return{
            newAction: null
        }
    },
    components: { EditActionWrapper },
    props: ['question'],
    methods: {
        ...mapActions({
            addNewAction: 'logic/addNewAction',
        }),
        async handleAddAction(){
            await this.addNewAction({question: this.question})
        }
    }
}
</script>