<template>
    <v-card class="script-edit-card pa-3" @click="$emit('click')" dark color="secondary lighten-1" elevation="0">
        <div v-if="scriptPreview" class="success--text pb-2 text-caption">{{scriptPreview}}</div>
        <div text class="text-body-2"><v-icon left>mdi-code-braces</v-icon>Edit Script</div>
    </v-card>
</template>

<script>

export default {
    name: "ScriptPreviewPanel",
    props: {
        scriptText: {
            required: true,
        },
    },
    computed: {
        scriptPreview(){
            if(this.scriptText){
                return  this.scriptText.length > 200? this.scriptText.slice(0, 200)+'...' : this.scriptText
            }
            return ''
        },
    }
};
</script>

<style lang="scss" scoped>
    .script-edit-card {
        font-family: "Roboto Mono", monospace;
    }
</style>