<template>
    <div class="main-container">
        <div v-if="!hideHeader" class="d-flex align-center px-6 pt-6 pb-4">
            <div class="text-subtitle-1 mr-4" style="white-space: nowrap;">Logic for</div>
            <div class="d-flex align-center question-type-badge question-type-badge-light">
                <v-icon size="16" color="primary darken-1" v-text="questionIcons[question.type]"></v-icon>
                <div class="text-body-2">{{question.order+1}}</div>
            </div>
            <div class="text-caption flex-grow-1 px-2 ellipsis-text">
                <span v-if="questionText.length<28">{{ questionText }}</span>
                <v-tooltip :max-width="260" v-else top>
                    <template v-slot:activator="{ on, attrs }">
                        <span style="cursor: pointer;" v-bind="attrs" v-on="on">{{questionText}}</span>
                    </template>
                    <span class="text-caption">{{questionText}}</span>
                </v-tooltip>
            </div>
        </div>
        <div class="scroll-container pa-6 pt-2">
            <actions-list :question="question"></actions-list>
            <add-action :question="question"></add-action>
            <div class="my-4">
                <v-tooltip :max-width="260" bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <div>
                            <v-card disabled v-on="on" v-bind="attrs" style="pointer-events: auto; cursor: not-allowed;" rounded elevation="0" color="grey lighten-4 pa-3">
                                <div v-if="nextQuestion" class="d-flex align-center">
                                    <!-- TODO change text if there is some logic before the default -->
                                    <div class="text-caption">By default</div>
                                    <v-spacer></v-spacer>
                                    <div class="text-caption mr-2">
                                            go to
                                    </div>
                                    <div class="d-flex align-center question-type-badge question-type-badge-dark">
                                        <v-icon size="16" color="white">{{questionIcons[nextQuestion.type]}}</v-icon>
                                        <div class="text-body-2">{{nextQuestion.order+1}}</div>
                                    </div>
                                </div>
                                <div class="d-flex align-center" v-else>
                                    <div class="text-caption">By default</div>
                                    <v-spacer></v-spacer>
                                    <v-chip label small class="text-caption" color="success darken-1">
                                        <v-icon size="16" left color="white">mdi-flag-checkered</v-icon>
                                        <div class="text-caption">Submit</div>
                                    </v-chip>
                                </div>
                            </v-card>
                        </div>
                    </template>
                    <span class="text-caption">Default action cannot be removed or changed. To override its behavior, add action.</span>
                </v-tooltip>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { questionIcons } from '../../../../../configs/questionTypes';
import ActionsList from './ActionsList.vue';
import AddAction from './AddAction.vue';
export default {
    components: { AddAction, ActionsList },
    props: {
        questionNode: {
            required: true,
            type: Object
        },
        hideHeader: {
            type: Boolean,
            default: false
        },

    },
    data(){
        return {
          questionIcons
        }
    },
    computed: {
        ...mapGetters({
            questions: 'logic/questions',
        }),
        question(){
            return this.questionNode.data.question
        },

        questionText(){
            let text = this.question.text
            if (!text || text === 'Type your question here') {
                text = '...'
            }
            return `${this.question.is_optional ? "" : "* "}${text}`;
        },

        nextQuestion(){
            const nextQuestion = this.questions.find(question => question.order > this.question.order) 
            return nextQuestion
        }
    }
}
</script>

<style lang="scss" scoped>
.main-container{
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 0;
} 
.ellipsis-text{
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.question-type-badge{
    width: 52px;
    height: 24px;
    min-width: 52px;
    justify-content: space-between;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 4px;
    padding-bottom: 4px;
    border-radius: 4px;

    background-color: var(--v-primary-lighten5);
    color: var(--v-primary-darken1);
    &-light{
        background-color: var(--v-primary-lighten5);
        color: var(--v-primary-darken1);
    }
    &-dark{
        background-color: var(--v-primary-base);
        color: white;
    }
}
.scroll-container{
    overflow-y: auto;
    height: 100%;
}
</style>