<template>
    <v-autocomplete
        :value="value"
        :items="items"
        outlined
        dense
        chips
        item-text="text"
        item-value="id"
        hide-details="auto"
        class="mb-2"
        @input="val => $emit('input',val)"
        :rules="rules"
        :error="error"
        :placeholder="placeholder"
        label=""
    >
        <template v-slot:selection="data">
            <div style="max-width: 200px">
                <question-item v-if="data.item" :question="data.item"></question-item>
            </div>
        </template>
        <template v-slot:item="data">
            <div style="max-width: 200px">
                <question-item v-if="data.item" :question="data.item"></question-item>
            </div>
        </template>
    </v-autocomplete>
</template>

<script>
import QuestionItem from './QuestionItem.vue'
export default {
    data() {
        return {
            rules: [
                value => !!value || 'Required',
            ]
        };
    },
    props: ['value', 'items', 'error', 'placeholder'],
    components: { QuestionItem },
}
</script>