<template>
    <v-fade-transition hide-on-leave>
        <v-form ref="form" v-model="valid">
            <slot v-if="!editingAction"></slot>
            <v-card v-else elevation="0" outlined class="pa-2 pb-4 mb-4">
                <v-card class="d-flex align-center px-2 py-1" elevation="0" color="grey lighten-4">
                    <v-checkbox v-model="alwaysTrigger" small dense hide-details="auto" color="primary" class="text-caption pa-0 ma-0" id="always-trigger-condition-checkbox">
                        <template v-slot:label>
                            <div class="text-caption">Always trigger action</div>
                        </template>
                    </v-checkbox>
                </v-card>
                <template v-if="!alwaysTrigger">
                    <div class="my-3 d-flex align-center justify-center">
                        <v-divider></v-divider>
                        <div class="text-caption text-center px-1 grey--text">or specify conditions bellow</div>
                        <v-divider></v-divider>
                    </div>
                    <condition-editor 
                        v-for="(condition, index) in editingAction.conditions" 
                        :key="`condition-${question.id}-${index}`" 
                        :index="index"
                        :condition="condition"
                        :question="question">
                    </condition-editor>
                    <div>
                        <v-btn @click="handleAddCondition" small text color="primary" class="mr-2"><v-icon left>mdi-plus</v-icon>Add condition</v-btn>
                    </div>
                </template>
                <div class="mt-4">
                    <div v-if="!alwaysTrigger" class="mb-2 text-subtitle-2">Then</div>
                    <v-alert v-if="hasWrongQuestion" outlined class="my-2 pa-2" text type="error">
                        The previously selected question is not available anymore. Please select a new one or remove this action.
                    </v-alert>
                    <v-select
                        :items="actionOptions"
                        item-text="name"
                        item-value="value"
                        dense
                        outlined
                        hide-details="auto"
                        class="mb-2"
                        v-model="actionType"
                    >
                        <template v-slot:selection="{item}">
                            <div class="text-caption">
                                <v-icon color="primary" left class="mr-2">{{actionIcons[item.value]}}</v-icon>
                                {{ item.name }}
                            </div>
                        </template>
                        <template v-slot:item="{ item }">
                            <upgrade-wrapper :body-text="item.description || ''" v-if="item.blocked" :blocked="!!item.blocked">
                                <div class="text-caption">
                                    <v-icon color="primary" left class="mr-2">{{actionIcons[item.value]}}</v-icon>
                                    {{ item.name }}
                                    <v-chip class="ml-1" dark color="deepPurple accent-1" label x-small v-if="item.blocked">Paid</v-chip>
                                </div>
                            </upgrade-wrapper>
                            <div v-else class="text-caption">
                                    <v-icon color="primary" left class="mr-2">{{actionIcons[item.value]}}</v-icon>
                                    {{ item.name }}
                                    <v-chip class="ml-1" dark color="deepPurple accent-1" label x-small v-if="item.blocked">Paid</v-chip>
                            </div>
                        </template>
                
                    </v-select>
                    <variable-selector 
                        v-if="actionType==='go-to'"
                        v-model="to"
                        :items="availableQuestions"
                    ></variable-selector>
                    <v-text-field
                        v-if="actionType==='redirect'"
                        v-model="to"
                        prepend-icon="mdi-link"
                        outlined
                        dense
                        :rules="linkRules"
                        validate-on-blur
                        placeholder="http://"
                        clearable
                        hide-details="auto"
                        @change="handleLinkChange"
                    ></v-text-field>
                    <v-checkbox
                        v-if="actionType==='redirect'"
                        v-model="submitAnswers"
                        label="Submit answers before redirect"
                        color="primary"
                    ></v-checkbox>

                    <!-- LOOP FIELDS -->
                    <template  v-if="actionType==='loop'">
                        <v-btn-toggle
                            class="mb-2"
                            v-model="loopSourceType"
                            mandatory
                        >
                            <v-btn small value="question">
                                <v-icon small left>mdi-crosshairs-question</v-icon>
                                <span class="text-caption">Question</span>
                            </v-btn>
                            <v-btn small value="code">
                                <v-icon small left>mdi-code-tags</v-icon>
                                <span class="text-caption">JS Script</span>
                            </v-btn>
                        </v-btn-toggle>
                        <div class="text-caption mb-2 grey--text text--darken-1">
                            {{
                                loopSourceType === 'question' ? 
                                'Select a question with a list of options to loop through. The loop will use only respondent selections.' : 
                                'Write a script that returns an array of options to loop through.'
                            }}
                        </div>
                        <loop-source-selector
                            v-if="loopSourceType === 'question'"
                            v-model="loopSourceQuestion"
                            :items="availableLoopSources"
                            placeholder="Select a loop list source"
                            class="text-body-2"
                        ></loop-source-selector>
                        <v-card class="script-edit-card pa-3" v-if="loopSourceType === 'code'" @click="functionDialog=true" dark color="secondary lighten-1" elevation="0">
                            <div v-if="scriptPreview" class="success--text pb-2 text-caption">{{scriptPreview}}</div>
                            <div text class="text-body-2"><v-icon left>mdi-code-braces</v-icon>Edit Script</div>
                            <v-dialog scrollable max-width="1100" v-model="functionDialog">
                                <script-editor :script="script" :viewable="functionDialog"  @save="handleScriptSave" :rules="scriptRules"></script-editor>
                            </v-dialog>
                        </v-card>
                    </template>
                </div>
                <div class="d-flex align-center mt-4">
                    <v-btn @click="handleSaveAction" :loading="saving" :disabled="saving" small elevation="0" color="secondary" class="mr-2">Save action</v-btn>
                    <v-btn @click="handleCancel" small text color="secondary">Cancel</v-btn>
                </div>
            </v-card>
        </v-form>
    </v-fade-transition>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { CHECKBOX, MATRIX } from '../../../../../configs/questionTypes';
import ConditionEditor from './ConditionEditor.vue'
import LoopSourceSelector from './LoopSourceSelector.vue';
import ScriptEditor from './ScriptEditor.vue';
import VariableSelector from './VariableSelector.vue'
import UpgradeWrapper from '@/components/UpgradeWrapper.vue';

const LinkReg = new RegExp("^(http|https)://", "i");
export default {
    components: { ConditionEditor, VariableSelector, ScriptEditor, LoopSourceSelector, UpgradeWrapper },
    props: ['question', 'actionId'],
    data(){
        return {
            valid: true,
            saving: false,
            selectedAction: 'go-to',
            functionDialog: false,
            conditions: [],
            linkRules: [
                value => !!value && !!value.trim() || 'The link is required',
                value => LinkReg.test(value) || 'The link should start with http:// or https://',
                value => {
                    const pattern = /(([\w]+:)?\/\/)?(([\d\w]|%[a-fA-f\d]{2,2})+(:([\d\w]|%[a-fA-f\d]{2,2})+)?@)?([\d\w][-\d\w]{0,253}[\d\w]\.)+[\w]{2,63}(:[\d]+)?(\/([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)*(\?(&?([-+_~.\d\w]|%[a-fA-f\d]{2,2})=?)*)?(#([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)?/;
                    return pattern.test(value) || 'Invalid link'
                }
            ],
            actionIcons: {
                'go-to': 'mdi-ray-start-arrow',
                'skip': 'mdi-debug-step-over',
                'submit': 'mdi-flag-checkered',
                'drop-off': 'mdi-cancel',
                'redirect': 'mdi-open-in-new',
                'loop': 'mdi-repeat-variant'
            },
            scriptRules: [
                value => Array.isArray(value) || 'The script should return an array'
            ],
            // eslint-disable-next-line
            defaultScript: `// Example script\n// Access dynamic variables and their fields\nconst exampleVariable = dynamicVariables.exampleVariable;\nconst exampleField = dynamicVariables.fields.exampleField;\n\n// Perform operations or logic here\nconst spaceItems = [\n    { item: \"Moon Rock\", coolness: 20 },{ item: \"Alien Sock\", coolness: 75 },\n    { item: \"Asteroid Dust\", coolness: 15 },{ item: \"Space Pizza\", coolness: 90 },\n];\n\nspaceItems.sort((a, b) => b.coolness - a.coolness);\n\n// Log for debugging\nconsole.log('Coolest Space Item 😎:',spaceItems[0].item)\n\n// Return an array\nreturn Array.isArray(spaceItems)? spaceItems : [];`
        }
    },
    inject: ['survey'],
    computed: {
        ...mapGetters({
            edits: 'logic/edits',
            questions: 'logic/questions',
            features: 'auth/features'
        }),
        actionOptions(){
            const options = [
                {
                    name: 'Go to',
                    value: 'go-to'
                },
                {
                    name: 'Skip this question',
                    value: 'skip'
                },
                {
                    name: 'Submit',
                    value: 'submit'
                },
                {
                    name: 'Disqualify',
                    value: 'drop-off'
                },
                {
                    name: 'Redirect to a webpage',
                    value: 'redirect'
                }
            ]

            options.push({
                    name: 'Loop this question',
                    value: 'loop',
                    blocked: this.features.dynamic_loops === false,
                    description: '"Loop" allows you to repeat the question for each item in a list. You can loop through a list of options from a previous question (checkbox or matrix) or write a JS script that returns an array of options.'
            })

            return options
        },
        editingAction(){
            const editAction = this.edits[this.question.id]
            if(editAction){
                // new action
                if(!this.actionId && !editAction.created_at){
                    return editAction
                }
                if(this.actionId && editAction.id === this.actionId){
                    return editAction
                }
            }
            return null
        },
        availableQuestions(){
            return this.questions.filter(question => question.order > this.question.order) 
        },
        availableLoopSources(){
            return this.questions.filter(question => question.order < this.question.order && [CHECKBOX, MATRIX].includes(question.type))
        },

        script(){
            return this.editingAction.loop_source && this.editingAction.loop_source.script || this.defaultScript
        },
        scriptPreview(){
            if(this.editingAction.loop_source && this.editingAction.loop_source.script){
                return  this.editingAction.loop_source.script.length > 200? this.editingAction.loop_source.script.slice(0, 200)+'...' : this.editingAction.loop_source.script
            }
            return ''
        },

        loopSourceType: {
            get(){
                return this.editingAction.loop_source ? this.editingAction.loop_source.type : 'question'
            },
            set(val){

                const loop_source = {
                    ...this.editingAction.loop_source,
                    type: val
                }
                if(val === 'question' && !loop_source.id){
                    loop_source.id = null
                }
                if(val === 'code' && !loop_source.script){
                    loop_source.script = ''
                }
                this.updateNewAction({question: this.question, action: {
                    ...this.editingAction,
                    loop_source
                }})
            }
        },
        loopSourceQuestion: {
            get(){
                return this.editingAction.loop_source ? this.editingAction.loop_source.id : null
            },
            set(val){

                const loop_source = {
                    ...this.editingAction.loop_source,
                    id: val || null
                }

                this.updateNewAction({question: this.question, action: {
                    ...this.editingAction,
                    loop_source
                }})
            }
        },
        actionType: {
            get(){
                return this.editingAction.type
            },
            set(val){
                if(val === 'loop' && this.features.dynamic_loops === false){
                    val = this.editingAction.type === 'go-to' ? 'skip' : 'go-to'
                }
                if(this.editingAction.type !== val){
                    this.updateNewAction({question: this.question, action: {
                        ...this.editingAction,
                        type: val,
                        to: null,
                        loop_source: null,
                        submit_answers: ['redirect', 'submit'].includes(val)
                    }})
                }
            }
        },
        to: {
            get(){
                if(this.editingAction.type === 'redirect'){
                    return this.editingAction.to || ''
                }
                return this.editingAction.to
            },
            set(val){
                this.updateNewAction({question: this.question, action: {
                    ...this.editingAction,
                    to: val
                }})
            }
        },

        submitAnswers: {
            get(){
                return this.editingAction.submit_answers
            },
            set(val){
                this.updateNewAction({question: this.question, action: {
                    ...this.editingAction,
                    submit_answers: !!val // making sure its boolean
                }})
            }
        },

        alwaysTrigger: {
            get(){
                return this.editingAction.always_trigger || false
            },
            set(val){
                this.updateNewAction({question: this.question, action: {
                    ...this.editingAction,
                    always_trigger: !!val // making sure its boolean
                }})
            }
        },

        hasWrongQuestion(){
            if(this.actionType === 'go-to' && this.to){
                const question = this.availableQuestions.find(question=>question.id=== this.to)
                return !question
            }
            return false
        }
        
    },
    methods: {
        ...mapMutations(['showSnackBar']),
        ...mapActions({
            addNewAction: 'logic/addNewAction',
            addNewConditionToAction: 'logic/addNewConditionToAction',
            deleteEditAction: 'logic/deleteEditAction',
            updateNewAction: 'logic/updateNewAction',
            saveEditAction: 'logic/saveEditAction'
        }),
        handleAddAction(){
            this.addNewAction({question: this.question})
        },
        handleAddCondition(){
            this.addNewConditionToAction(this.question)
        },
        handleCancel(){
            this.deleteEditAction(this.question)
        },

        handleScriptSave(script){
            this.updateNewAction({question: this.question, action: {
                ...this.editingAction,
                loop_source: {
                    ...this.editingAction.loop_source,
                    type: 'code',
                    script
                }
            }})
            this.functionDialog = false
        },
        async handleSaveAction(){
            try {
                const valid = this.$refs.form.validate();
                if(!valid) {
                    return this.showSnackBar({
                        text: 'Some of the action properties are invalid.', 
                        color: 'error', 
                        timeout: 2000
                    })
                }
                this.saving = true
                const res = await this.saveEditAction({
                    survey: this.survey, 
                    question: this.question
                })
                if(res && res.status === 200){
                    this.handleCancel()
                    this.showSnackBar({
                        text: 'Action Saved', 
                        color: 'success', 
                        timeout: 1000
                    })
                }
                this.saving = false
            } catch (error) {
                this.saving = false
                // TODO pass error fromm backend to alert
                this.showSnackBar({
                    text: `Something went wrong. Can't save this action. Please try again later.`, 
                    color: 'error', 
                    timeout: 4000
                })
            }
        },
        handleLinkChange(value){
            if(value && value.trim() && !LinkReg.test(value)){
                this.to = `http://${value}`
            }
            
        }
    }
}
</script>

<style lang="scss" scoped>
.operator-select{
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-collapse: collapse;
    max-width: 100px;
    font-size: 12px;
}
.condition-value{
    margin-left: -1px;
    border-collapse: collapse;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}
.script-edit-card {
    font-family: "Roboto Mono", monospace;
}
</style>