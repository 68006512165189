<template>
    <div v-if="question" class="condition-container mx-2">
        <div v-if="index===0" class="token">If</div>
        <template v-if="condition.script">
            <div class="d-flex align-center js-type-badge mr-1">
                <v-icon size="16" color="success darken-1" >mdi-code-tags</v-icon>
                <div class="text-body-2">JS</div>
            </div>
            <!-- add space after returns -->
            <div class="token">returns&nbsp;<b>true</b></div>
        </template>
        <template v-else>
            <div class="d-flex align-center question-type-badge mr-1">
                <v-icon size="16" color="primary darken-1" >{{questionIcons[question.type]}}</v-icon>
                <div class="text-body-2">{{question.order+1}}</div>
            </div>
            <span class="token font-weight-bold">{{operator}}</span>
            <span v-if="!['answered', 'not-answered'].includes(condition.op)" class="token text-bold">{{condition.value}}</span>
        </template>
        
        <span v-if="condition.log_op && conditions.length!==index+1" class="token">{{condition.log_op}}</span>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getOperatorSymbol } from '../../../../../configs/operators';
import { questionIcons } from '../../../../../configs/questionTypes';
export default {
    data(){
        return {
            questionIcons
        }
    },
    props: ['condition', 'index', 'conditions'],
    computed: {
        ...mapGetters({
            questions: 'logic/questions',
        }),
        question(){
            return this.questions.find(question=> question.id === this.condition.variable.question_ref)
        },
        operator(){
            return getOperatorSymbol(this.condition.op)
        }
    },
}
</script>

<style lang="scss" scoped>
.condition-container{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
}
.token{
    display: flex;
    height: 24px;
    align-items: center;
    margin-right: 4px;
}
.question-type-badge{
    width: 52px;
    height: 24px;
    min-width: 52px;
    justify-content: space-between;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 4px;
    padding-bottom: 4px;
    border-radius: 4px;
    background-color: var(--v-primary-lighten5);
    color: var(--v-primary-darken1);
}

.js-type-badge{
    width: 52px;
    height: 24px;
    min-width: 52px;
    justify-content: space-between;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 4px;
    padding-bottom: 4px;
    border-radius: 4px;
    background-color: var(--v-success-lighten5);
    color: var(--v-success-darken1);
}
</style>
