<template>
    <form-builder class="layout" fluid>
       <logic-editor></logic-editor>
    </form-builder>
</template>

<script>
import FormBuilder from '../../../../../layouts/FormBuilder.vue'
import LogicEditor from './LogicEditor.vue';
export default {
    components: {
        FormBuilder,
        LogicEditor
    },
}
</script>

<style lang="scss" scoped>
.layout{
    height: 100%;
}
</style>