<template>
    <edit-action :question="question" :actionId="action.id">
        <v-hover v-slot="{ hover }">
            <v-card :color="hover? 'deep-purple lighten-5': ''" class="d-flex align-center pa-3" outlined>
                <conditions-description :action="action"></conditions-description>
                <v-tooltip v-if="hasError" bottom max-width="250px">
                    <template v-slot:activator="{ on, attrs }">
                        <div class="action-badge action-badge-error pa-1" v-bind="attrs" v-on="on">
                            <v-icon class="mr-1" color="error">mdi-heart-broken</v-icon>
                            <span class="text-caption">Broken action</span>
                        </div>
                    </template>
                    <span>This action is not active. Please edit or remove this action.</span>
                </v-tooltip>
                <v-tooltip v-else bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <div class="action-badge" v-bind="attrs" v-on="on">
                            <v-icon color="deep-purple accent-3">
                                mdi-lightning-bolt
                            </v-icon>
                            <span class="text-caption ellipsis-text">{{actionText}} </span>
                        </div>
                    </template>
                    <span>{{actionText}}</span>
                </v-tooltip>
                <div class="d-flex justify-end" v-if="hover">
                    <v-btn @click.stop="handleActionEdit" small icon>
                        <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn :loading="deleting" :disabled="deleting" @click="handleDelete" small icon>
                        <v-icon small>mdi-delete</v-icon>
                    </v-btn>
                </div>
            </v-card>
        </v-hover>
        <div v-if="isLast" class="py-2"></div>
        <div v-else class="text-caption d-flex grey--text py-2">
            <v-icon color="grey" small left>mdi-arrow-down-thin</v-icon>
            <span>if previous action was skipped</span>
        </div>
    </edit-action>
</template>

<script>
import {mapActions, mapGetters, mapMutations } from 'vuex';
import ConditionsDescription from './ConditionsDescription.vue';
import EditAction from './EditActionWrapper.vue';
export default {
    data(){
        return {
            deleting: false
        }
    },
    components: { EditAction, ConditionsDescription },
    props: ['action', 'question', 'isLast'],
    inject: ['survey'],
    computed:{
        ...mapGetters({
            questions: 'logic/questions',
        }),
        hasError(){
            return this.action.broken
        },
        toQuestion(){
            if(this.action.type === 'go-to'){
                return this.questions.find(question => question.id === this.action.to)
            }
            return null
        },
        actionText(){
            if(this.action.type === 'go-to'){
                return `Go to ${this.toQuestion.order+1}`
            }
            if(this.action.type === 'redirect'){
                return `Redirect to ${this.action.to.replace(/^https?:\/\//, '')}`
            }
            if(this.action.type === 'submit'){
                return `Submit`
            }
            if(this.action.type === 'drop-off'){
                return `Disqualify`
            }
            if(this.action.type === 'skip'){
                return `Skip`
            }
            if(this.action.type === 'loop'){
                return `Loop`
            }
            return 'Action'
        }
    },
    methods: {
        ...mapMutations({
            setEditAction: 'logic/setEditAction',
            'showSnackBar': 'showSnackBar'
        }),
        ...mapActions({
            deleteAction: 'logic/deleteAction',
        }),
        handleActionEdit(){
            // we need to make sure that editing action doesn't reference original objects
            const editAction = {...this.action, conditions: [...this.action.conditions]}
            this.setEditAction({questionId: this.question.id, action: editAction})
        },

        async handleDelete(){
            try {
                this.deleting = true
                this.deleteAction({surveyId: this.survey.id, questionId: this.question.id, actionId: this.action.id})
                this.deleting = false
                this.showSnackBar({
                    color: "success",
                    timeout: 1000,
                    text: "Removed action."
                })
            } catch (error) {
                this.deleting = false
                this.showSnackBar({
                    color: "error",
                    timeout: 4000,
                    text: "Something went wrong. Cannot remove action. Please try again later."
                })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.action-badge{
    display: flex;
    border-radius: 4px;
    background-color: var(--v-deepPurple-lighten5);
    color: var(--v-deep-purple-darken4);
    padding: 4px;
    padding-right: 12px;
    white-space: nowrap;
    max-width: 150px;
    align-items: center;

    &-error{
        background-color: var(--v-error-lighten5);
        color: var(--v-error-darken4);
    }
}
.ellipsis-text{
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
</style>