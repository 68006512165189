<template>
    <v-hover v-slot="{ hover }">
        <v-card rounded elevation="0" :color="hover? 'grey lighten-4': 'grey lighten-5'" class="mb-4 pa-2">
            <div style="min-height: 36px;" class="d-flex align-center">
                <div class="mb-2 text-subtitle-2">{{
                    condition && condition.script? 'If JS script returns true':
                    'If answer to'
                    }}</div>
                <v-spacer></v-spacer>
                <v-tooltip v-if="hover" bottom>
                    <template v-slot:activator="{ on }">
                        <upgrade-wrapper bodyText="'JS Script Condition' lets you write JavaScript scripts that return true or false, enabling complex logic. You can also access Dynamic Variables and metadata within the script." :blocked="!conditionScripts">
                            <v-btn :color="conditionScripts?'grey darken-2':'deepPurple accent-3'" small icon v-on="on" @click="handleScriptConditionToggle">
                                <v-icon size="20">{{condition.script ? 'mdi-form-dropdown' : 'mdi-code-tags'}}</v-icon>
                            </v-btn>
                        </upgrade-wrapper>
                    </template>
                    <span class="text-caption">{{ condition.script ? 'Switch to UI condition builder.': 'Switch to JS script condition'}}</span>
                </v-tooltip>
                <v-btn @click="handleDelete" small v-if="hover && !!index" icon>
                    <v-icon size="16">mdi-delete</v-icon>
                </v-btn>
            </div>
            <template v-if="condition.script">

                <script-preview-panel :scriptText="scriptText" @click="functionDialog=true"></script-preview-panel>
                <div class="mb-2"></div>
                <v-dialog scrollable max-width="1100" v-model="functionDialog">
                    <script-editor :script="scriptText" :viewable="functionDialog"  @save="handleScriptSave" :rules="scriptRules"></script-editor>
                </v-dialog>
            </template>
            <template v-else>
                <variable-selector 
                    v-model="selectedQuestion"
                    :items="availableQuestions"
                    :error="showError"
                >
                </variable-selector>
                <div v-if="selectedQuestion && selectedQuestion.type==='matrix'">
                        <v-select
                            v-model="selectedMatrixRow"
                            :items="matrixRows"
                            item-text="title"
                            item-value="id"
                            dense
                            outlined
                            hide-details="auto"
                            :class="['mb-2 text-body-2']"
                            :rules="[requiredRule]"
                            clearable
                            placeholder="Select row"
                            prepend-inner-icon="mdi-table-row"
                        ></v-select>
                        <v-select
                            v-model="selectedMatrixCol"
                            :items="matrixCols"
                            item-text="title"
                            item-value="id"
                            dense
                            outlined
                            hide-details="auto"
                            :class="['mb-2 text-body-2']"
                            :rules="[requiredRule]"
                            clearable
                            placeholder="Select column/group"
                            prepend-inner-icon="mdi-table-column"
                        ></v-select>
                </div>
                <div class="d-flex" v-if="selectedQuestion && (selectedQuestion.type==='matrix'? selectedMatrixCol && selectedMatrixRow : true)">
                    <v-select
                        v-model="selectedOperator"
                        :items="availableOperators"
                        item-text="name"
                        item-value="value"
                        dense
                        outlined
                        hide-details="auto"
                        :class="['mb-2 text-body-2', {'operator-select': !isSelectedBoolean} ]"
                        :rules="[requiredRule]"
                        placeholder="Select operator"
                    ></v-select>
                    <v-text-field 
                        :rules="[requiredRule]" 
                        v-if="(['text', 'number'].includes(ruleType) || showNumberValueInput)
                        && !isSelectedBoolean" 
                        v-model="conditionValue" 
                        :type="ruleType==='text'?'text':'number'" 
                        :placeholder="selectedQuestion && selectedQuestion.type==='phone-number'&& '+11234567890'||'value'" 
                        hide-details="auto" 
                        class="text-body-2 condition-value" 
                        outlined 
                        dense
                    ></v-text-field>
                    <v-menu
                        v-else-if="ruleType==='date' && !isSelectedBoolean"
                        ref="datePickerMenu"
                        v-model="datePickerMenu"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="conditionValue"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            hide-details="auto" 
                            class="text-body-2 condition-value" 
                            outlined 
                            dense
                            :rules="[requiredRule]"
                        ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="conditionValue"
                            no-title
                            scrollable
                        >
                        <v-spacer></v-spacer>
                        </v-date-picker>
                    </v-menu>
                    <v-select
                        v-if="showOptionSelectorValueInput"
                        v-model="conditionValue"
                        :items="availableOptions"
                        item-text="label"
                        item-value="value"
                        dense
                        outlined
                        hide-details="auto"
                        class="mb-2 text-body-2 condition-value"
                        :rules="[requiredRule, shouldMatchOptions]"
                        placeholder="Select option"
                    ></v-select>
                </div>
    
            </template>
            <div v-if="selectedQuestion && condition.log_op && editingAction.conditions && editingAction.conditions.length !== index+1" class="mb-2" style="width: 100px">
                    <v-btn-toggle
                        v-model="logicalOperator"
                        mandatory
                        dense
                    >
                        <v-btn>
                            and
                        </v-btn>
                        <v-btn>
                            or
                        </v-btn>
                    </v-btn-toggle>
                </div>
        </v-card>
    </v-hover>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import QTypes, { defaultConditionValue, variableType } from '../../../../../configs/questionTypes'
import operators, { 
    ANSWERED, NOT_ANSWERED, IS, IS_NOT, 
    NUMBER_OF_SELECTED_OPTIONS_IS, 
    NUMBER_OF_SELECTED_OPTIONS_IS_NOT, 
    NUMBER_OF_SELECTED_OPTIONS_IS_LOWER_THAN, 
    NUMBER_OF_SELECTED_OPTIONS_IS_GREATER_THAN } from '../../../../../configs/operators'
import VariableSelector from './VariableSelector.vue'
import ScriptEditor from './ScriptEditor.vue'
import ScriptPreviewPanel from './ScriptPreviewPanel.vue'
import UpgradeWrapper from '../../../../../components/UpgradeWrapper.vue'

const DEFAULT_RADIO_GROUP_NAME = "default_radio";
const DEFAULT_CHECKBOX_GROUP_NAME = "default_checkbox";
export default {
    components: { VariableSelector, ScriptEditor, ScriptPreviewPanel, UpgradeWrapper },
    props: ['question', 'condition', 'index'],
    data(){
        return {
            datePickerMenu: false,
            requiredRule: value => !!value || 'Required',
            shouldMatchOptions: value => !!this.availableOptions.find(option=> option.value === value) || 'Select new option',
            functionDialog: false,
            // script example returning true or false
            defaultScript: "// EXAMPLE SCRIPT - THIS FUNCTION MUST RETURN ONLY TRUE OR FALSE\n/*\n    // Access dynamic variables and their fields\n    const exampleVariable = dynamicVariables.exampleVariable;\n    const exampleField = dynamicVariables.fields.exampleField;\n\n    // Perform operations or logic here\n    const spaceItems = [\n        { item: 'Moon Rock', coolness: 20 },\n        { item: 'Alien Sock', coolness: 75 },\n        { item: 'Asteroid Dust', coolness: 15 },\n        { item: 'Space Pizza', coolness: 90 },\n    ];\n\n    // Define the coolness threshold\n    const coolnessThreshold = 50;\n\n    // Check if any item has coolness above the threshold\n    const isCoolItemPresent = spaceItems.some(item => item.coolness > coolnessThreshold);\n\n    // Log for debugging\n    console.log('Is there any cool item above threshold:', isCoolItemPresent);\n*/\n// MUST RETURN ONLY TRUE OR FALSE\nreturn true; // or return false;",
            scriptRules: [
                // The script should return a true or false value
                value =>  typeof value === 'boolean' || 'Script should return a boolean value',
            ],
        }
    },
    computed: {
        ...mapGetters({
            questions: 'logic/questions',
            edits: 'logic/edits',
            features: 'auth/features'
        }),
        conditionScripts(){
            return this.features && this.features.condition_scripts
        },
        showError(){
            if(this.selectedQuestion && this.selectedQuestion.id && this.availableQuestions){
                return !this.availableQuestions.find(q=>q.id === this.selectedQuestion.id)
            }
            return false
        },
        editingAction(){
            return this.edits[this.question.id]
        },
        availableQuestions(){
            return this.questions.filter(question => question.type !== QTypes.DESCRIPTION_SLIDE && question.order <= this.question.order) 
        },
        ruleType(){
            if(this.selectedQuestion?.type === 'matrix' && this.selectedMatrixCol){
                    if(['radio', 'dropdown'].includes(this.selectedMatrixCol.type)){
                        return 'options'
                    }else if(this.selectedMatrixCol.type === 'checkbox'){
                        return 'multiple_options'
                    }else if(this.selectedMatrixCol.type === 'numeric'){
                        return 'number'
                    }else if(this.selectedMatrixCol.type === 'text'){
                        return 'text'
                    }
            }
            return variableType(this.selectedQuestion);
        },
        availableOperators(){
            return operators[this.ruleType]
        },
        availableOptions(){
            if(this.selectedQuestion.type === QTypes.YESNO){
                return [
                    {label: 'Yes', value: 'yes'},
                    {label: 'No', value: 'no'},
                ]
            }

            if(this.selectedQuestion?.type === QTypes.MATRIX && this.selectedMatrixCol){
                if(['radio', 'checkbox'].includes(this.selectedMatrixCol.type)){
                    if([DEFAULT_CHECKBOX_GROUP_NAME, DEFAULT_RADIO_GROUP_NAME].includes(this.selectedMatrixCol.id)){
                        return this.selectedQuestion.properties.columns.reduce((acc, col) => {
                            if(col.type === this.selectedMatrixCol.type && !col.group_name){
                                acc.push({label: col.title, value: col.id})
                            }
                            return acc
                        }, [])
                    }else{
                        return this.selectedQuestion.properties.columns.reduce((acc, col) => {
                            if(col.type === this.selectedMatrixCol.type && col.group_name === this.selectedMatrixCol.id){
                                acc.push({label: col.title, value: col.id})
                            }
                            return acc
                        }, [])
                    }
                }else if(this.selectedMatrixCol.type === 'dropdown'){
                    return this.selectedMatrixCol.properties.options.map(option => ({label: option.value, value: option.id}))
                }
            }

            const options = [...this.selectedQuestion.multiple_choice_items].map(option => ({label: option.value, value: option.value})) || []
            if(this.selectedQuestion?.properties?.add_none_of_the_above){
                options.push({label: 'None of the above', value: 'None of the above'})
            }
            return options
        },

        isSelectedBoolean(){
            return [ANSWERED, NOT_ANSWERED].includes(this.selectedOperator)
        },
        matrixRows(){
            if(this.selectedQuestion.type === QTypes.MATRIX){
                return this.selectedQuestion.properties.rows || []
            }
            return []
        },
        matrixCols(){
            if(this.selectedQuestion.type === QTypes.MATRIX){
                const colMap = new Map()
                this.selectedQuestion.properties.columns.forEach(col => {
                    if(['radio', 'checkbox'].includes(col.type)){
                        if(col.group_name && !colMap.has(col.group_name)){
                            colMap.set(col.group_name, {id: col.group_name, title: col.group_name, type: col.type})
                        }else{
                            const key = col.type === 'radio'? DEFAULT_RADIO_GROUP_NAME: DEFAULT_CHECKBOX_GROUP_NAME
                            colMap.set(key, {id: key, title: key, type: col.type})
                        }
                    }else{
                        colMap.set(col.id, col)
                    }

                });

                return [...colMap.values()]
            }
            return []
        },

        selectedMatrixRow: {
            get(){
                if(this.condition.variable && this.condition.variable.row_id){
                    return this.matrixRows.find(row => row.id === this.condition.variable.row_id)
                }
                return null
            },
            set(val){
                this.updateNewActionCondition({
                    questionId: this.question.id,
                    condition: {
                        ...this.condition,
                        variable: {
                            ...this.condition.variable,
                            row_id: val
                        }
                    }
                })
            }
        },

        selectedMatrixCol: {
            get(){
                if(this.condition.variable && this.condition.variable.col_id){
                    return this.matrixCols.find(col => col.id === this.condition.variable.col_id)
                }
                return null
            },
            set(val){
                this.updateNewActionCondition({
                    questionId: this.question.id,
                    condition: {
                        ...this.condition,
                        variable: {
                            ...this.condition.variable,
                            col_id: val
                        },
                        value: null, 
                        op: null
                    },
                })
            }
        },

        selectedOperator: {
            get(){
               return this.condition.op
            },
            set(val){
                let value = this.condition.value
                if([ANSWERED, NOT_ANSWERED].includes(this.conditionValue)){
                    value = defaultConditionValue(this.ruleType, this.question)
                }
                if([ANSWERED, NOT_ANSWERED].includes(val)){
                    value = val
                }

                this.updateNewActionCondition({
                    questionId: this.question.id,
                    condition: {
                        ...this.condition,
                        op: val,
                        value
                    }
                })
            }
        },
        conditionValue: {
            get(){
               return this.condition.value
            },
            set(val){
                this.updateNewActionCondition({
                    questionId: this.question.id,
                    condition: {
                        ...this.condition,
                        value: val,
                    }
                })
            }
        },

        selectedQuestion: {
            get(){
                return this.questions.find(question => question.id === this.condition.variable.question_ref)
            },
            set(val){
                if(this.condition && this.condition.variable && this.condition.variable.question_ref !== val){
                    const newQuestion = this.questions.find(question => question.id === val)
                    const type = variableType(newQuestion)
                    const value = defaultConditionValue(type, newQuestion);


                    let condition = null

                    if(type === 'matrix'){
                        condition = {
                            ...this.condition,
                            variable: {
                                type,
                                question_ref: val,
                                row_id: null,
                                col_id: null
                            },
                            op: null,
                            value: null,
                        }
                    }else{
                        condition = {
                            ...this.condition,
                            variable: {
                                type,
                                question_ref: val
                            },
                            op: operators[type][0].value,
                            value,
                        }
                    }

                    this.updateNewActionCondition({
                        questionId: this.question.id,
                        condition
                    })
                }
            },
        },
        logicalOperator: {
            get(){
                if(this.condition.log_op === 'and'){
                    return 0
                }

                if(this.condition.log_op === 'or'){
                    return 1
                }
                
                return 0
            },
            set(val){
                this.updateNewActionCondition({
                    questionId: this.question.id,
                    condition: {
                        ...this.condition,
                        log_op: val===1? 'or': 'and'
                    }
                })
            },
        },
        showOptionSelectorValueInput(){
            if(this.isSelectedBoolean){
                return false
            }

            if(this.ruleType === 'options'){
                return true
            }

            if(this.ruleType === 'multiple_options'){
                if([IS, IS_NOT].includes(this.selectedOperator)){
                    return true
                }else{
                    return false
                }
            }
            return false
        },
        showNumberValueInput(){
            if(this.ruleType === 'multiple_options' 
                && [NUMBER_OF_SELECTED_OPTIONS_IS_LOWER_THAN, 
                NUMBER_OF_SELECTED_OPTIONS_IS_NOT, 
                NUMBER_OF_SELECTED_OPTIONS_IS, 
                NUMBER_OF_SELECTED_OPTIONS_IS_GREATER_THAN]
                .includes(this.selectedOperator)
            ){
                return true
            }
            return false
        },
        scriptText(){
            return this.condition.script_text || this.defaultScript
        },
    },
    methods: {
        ...mapActions({
            updateNewActionCondition: 'logic/updateNewActionCondition',
            deleteActionCondition: 'logic/deleteActionCondition'
        }),

        handleDelete(){
            this.deleteActionCondition({questionId: this.question.id, condition: this.condition})
        },
        handleScriptConditionToggle(){
            
            this.updateNewActionCondition({
                questionId: this.question.id,
                condition: {
                    ...this.condition,
                    script: !this.condition?.script,
                }
            })
            
        },
        handleScriptSave(script){
            this.updateNewActionCondition({
                questionId: this.question.id,
                condition: {
                    ...this.condition,
                    script_text: script,
                    script: true,
                }
            })

            this.functionDialog = false
        },


    }
}
</script>

<style lang="scss" scoped>
.operator-select{
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-collapse: collapse;
    max-width: 150px;
    font-size: 12px;
}
.condition-value{
    margin-left: -1px;
    border-collapse: collapse;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    min-width: 0;
}
</style>